import { render, staticRenderFns } from "./PaymentPlanSummaryBlock.vue?vue&type=template&id=fa9740ae&scoped=true"
import script from "./PaymentPlanSummaryBlock.vue?vue&type=script&lang=js"
export * from "./PaymentPlanSummaryBlock.vue?vue&type=script&lang=js"
import style0 from "./PaymentPlanSummaryBlock.vue?vue&type=style&index=0&id=fa9740ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa9740ae",
  null
  
)

export default component.exports