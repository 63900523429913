var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('svgicon',{staticClass:"arrow",attrs:{"name":"triangle"}}),_c('div',{staticClass:"payment-plan-summary",class:{
            completed: _vm.plan_completed,
            loading: _vm.loading,
            disabled: !_vm.can_approve,
        },on:{"click":_vm.handleClick}},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.estimate_ref))]),(_vm.loading)?_c('span',{staticClass:"tag"},[_vm._v(" - ")]):(_vm.tag_label)?_c('span',{staticClass:"tag"},[_vm._v(" "+_vm._s(_vm.tag_label)+" ")]):_vm._e()]),(_vm.loading)?_c('loader',{attrs:{"message":""}}):[(_vm.plan_completed)?[_c('div',{staticClass:"info-container"},[_c('span',{staticClass:"completed"},[_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.estimate_amount)))]),_vm._v(" over "),_c('strong',[_vm._v(_vm._s(_vm.invoices_count))]),_vm._v(" invoices ")])])]:(_vm.plan_pending || _vm.plan_planned)?[_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"totals"},[(_vm.overdue_amount > 0)?_c('div',{staticStyle:{"flex":"1"}},[_c('el-tooltip',{attrs:{"content":_vm.overdue_amount_string,"placement":"top"}},[_c('div',{staticClass:"overdue"})])],1):_vm._e(),(_vm.show_paid_amount)?[_c('span',{staticClass:"total paid",attrs:{"data-label":"paid"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.paid_amount))+" ")]),_c('span',[_vm._v("/")])]:_vm._e(),(_vm.show_invoiced_amount)?[_c('span',{staticClass:"total invoiced",attrs:{"data-label":"invoiced"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.invoiced_amount))+" ")])]:_vm._e(),(
                                _vm.show_invoiced_amount && _vm.show_planned_amount
                            )?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.show_planned_amount)?[_c('span',{staticClass:"total planned",attrs:{"data-label":"planned"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.planned_amount))+" ")])]:_vm._e(),(
                                _vm.show_planned_amount && _vm.show_estimate_amount
                            )?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.show_estimate_amount)?[_c('span',{staticClass:"total pending",attrs:{"data-label":"pending"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.estimate_amount))+" ")])]:_vm._e(),(_vm.plan_invoiced)?_c('span',{staticClass:"count"},[_c('strong',[_vm._v(_vm._s(_vm.invoices_paid))]),_vm._v(" / "),_c('strong',[_vm._v(_vm._s(_vm.invoices_count))]),_vm._v(" paid ")]):(_vm.invoices_count > 0)?_c('span',{staticClass:"count"},[_c('strong',[_vm._v(_vm._s(_vm.invoices_sent))]),_vm._v(" / "),_c('strong',[_vm._v(_vm._s(_vm.invoices_count))]),_vm._v(" sent ")]):_vm._e()],2),_c('div',{staticClass:"progress-bars"},[_c('el-progress',{attrs:{"percentage":_vm.planned_percent,"show-text":false,"color":"#0f263b"}}),_c('el-progress',{staticClass:"no-background",attrs:{"percentage":_vm.invoiced_percent,"show-text":false,"color":"#409eff"}}),_c('el-progress',{staticClass:"no-background",attrs:{"percentage":_vm.paid_percent,"show-text":false,"color":"#90d050"}})],1)])]:_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }